import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";

export default class DayTimeFrameController {
  constructor(vueInstance) {
    this.vue = vueInstance;
  }
  onSelectedTimeFrame() {
    this.vue.selectedHour = null;
    this.vue.selectedRunIndex = null;
    this.vue.selectedShiftIndex = null;
    this.vue.submit();
  }
  isPreviousTimeFrameDisabled() {
    if (!this.vue.activeFactory) return true;
    return (
      this.vue.selectedDate ===
      this.vue
        .getCurrentProductionDateTime()
        .minus({ months: this.vue.maxPastMonths })
        .toFormat(TimeUtils.DATE_FORMAT)
    );
  }
  isPresentButtonDisabled() {
    return this.vue.isLiveData;
  }
  isNextTimeFrameDisabled() {
    return this.vue.isLiveData; // If we're viewing live data, we should not be able to next a next time frame
  }
  showNavigation() {
    return true;
  }
  showPeriodName() {
    return false;
  }
  getDateLabel() {
    return `${this.vue.getDayOfWeek(this.vue.selectedDateTime)} ${this.vue.getDayAndMonth(this.vue.selectedDateTime)}`;
  }
  selectPrevious() {
    this.vue.selectedDate = this.vue.selectedDateTime.minus({ days: 1 }).toFormat(TimeUtils.DATE_FORMAT);
    this.vue.submit();
  }
  selectNext() {
    this.vue.selectedDate = this.vue.selectedDateTime.plus({ days: 1 }).toFormat(TimeUtils.DATE_FORMAT);
    this.vue.submit();
  }
  selectNow() {
    this.vue.selectedDateTime = TimeUtils.getBusinessDateTime(
      DateTime.now().setZone(this.vue.timezone),
      this.vue.productionDayMinutesFromMidnight,
      this.vue.isCalendarDayBusinessDay,
    );
    this.vue.submit();
  }
  selectDate() {
    this.vue.submit();
  }
  checkDateTime(currentDateTime) {
    const currentDay = currentDateTime.get("day");
    if (currentDay !== this.vue.selectedDateTime.get("day")) {
      this.vue.selectedDateTime = currentDateTime;
      this.vue.submit();
    }
  }
}
